html {
  scroll-behavior: smooth;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}  */
body::-webkit-scrollbar {
  width: 0.8em;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: rgba(41, 191, 59, 0.62);
  outline: 1px solid rgba(53, 136, 76, 0.191);
  border-radius: 15%;
}
.catedoeies__div {
  background-image: url(images/liquid-cheese.svg);
  background-size: cover; /* This will make sure the background image covers the entire container */
  background-repeat: no-repeat;
}
.contactLeft__section {
  background-image: url(images/abstract-envelope.svg);
  background-size: cover; /* This will make sure the background image covers the entire container */
  background-repeat: no-repeat;
}
.contactRight__section {
  background-image: url(images/sun-tornado.svg);
  background-size: cover; /* This will make sure the background image covers the entire container */
  background-repeat: no-repeat;
}
.back_img {
  background-image: url(images/home_back.png);
  background-size: cover; /* This will make sure the background image covers the entire container */
  background-repeat: no-repeat;
  width: 100%;
  padding-top: 70px;
  padding-bottom: 70px;
}
.home_div {
  width: 80%;
  height: 450px;
  top: 30px;
  margin: auto;

  /* border: 4.5px solid rgb(15, 96, 15); */
  border-radius: 30px;
  backdrop-filter: blur(50px);
}
@media (min-width: 320px) {
  .home_div {
    width: 96%;
  }
}
.back_div {
  background: linear-gradient(
    90deg,
    rgb(3 197 146 / 93%) 0%,
    rgb(57 137 57) 100%
  );
}

@media (min-width: 320px) {
  .home_div {
    width: 100%;
  }
}
@media (min-width: 640px) {
  .home_div {
    width: 80%;
  }
}
/*******Animation********/

.card-container {
  margin: 0 auto;
  padding: 2rem;
}

.card {
  align-items: center;
  border-radius: 0.25rem;
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
}

.ball {
  background: url("./images/122.png");
  border-radius: 33%;
  height: 8rem;
  position: absolute;
  background-size: cover;
  width: 8rem;
}

/* Square */
.square-card {
  background-color: var(--frostee);
}

.square-card::before {
  animation: shadow 1.6s ease-in-out infinite both;
  background-color: rgb(8 255 107 / 52%);
  border-radius: 50%;
  bottom: 2rem;
  box-shadow: 0 0 0 8px rgb(0 245 121 / 71%);
  content: "";
  height: 0.1rem;
  left: 41%;
  position: absolute;
  right: 41%;
}

.down {
  animation: down 1.6s ease-in infinite both;
  bottom: 2rem;
  position: absolute;
}

.down .up {
  animation: up 1.6s ease-in-out infinite both;
}

.down .up .squeeze {
  animation: squeeze 1.6s cubic-bezier(0.72, 0.03, 0.28, 0.97) infinite both;
  transform-origin: 50% 100%;
}

.down .up .squeeze .rotate-in {
  animation: rotate-in 1.6s ease-out infinite both;
}

.down .up .squeeze .rotate-in .rotate-out {
  animation: rotate-out 1.6s ease-in infinite both;
}

.square {
  background-color: #6cfd30a6;
  height: 2rem;
  width: 2rem;
}

@keyframes down {
  0% {
    transform: translateY(-10rem);
  }
  20%,
  100% {
    transform: translateY(0);
  }
}

@keyframes up {
  0%,
  75% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10rem);
  }
}

@keyframes squeeze {
  0%,
  4% {
    transform: scale(1);
  }
  45% {
    transform: scale(1.8, 0.4);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes rotate-in {
  0% {
    transform: rotate(-135deg);
  }
  20%,
  100% {
    transform: rotate(0deg);
  }
}

@keyframes rotate-out {
  0%,
  80% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(135deg);
  }
}

@keyframes shadow {
  0%,
  100% {
    transform: scaleX(0.3);
  }
  45%,
  50% {
    transform: scaleX(0.8);
  }
}
/* End square */

/* Ball bouncing */
.ball-bouncing {
  background-color: var(--lemon-chiffon);
}

.ball-bouncing::before {
  animation: shadowBouncing 1.2s ease-in infinite;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  bottom: 2rem;
  content: "";
  height: 0.15rem;
  position: absolute;
  width: 2rem;
}

.ball-bouncing .ball {
  animation: ballBouncing 1.2s ease-in infinite;
  bottom: 2.1rem;
  transform-origin: bottom;
}

@keyframes ballBouncing {
  0%,
  100% {
    transform: scale(1.5, 0.5);
  }
  20% {
    transform: scaleY(1.2);
  }
  40%,
  80% {
    transform: translateY(-3rem);
  }
  70% {
    transform: translateY(-5rem);
  }
  90% {
    transform: translateY(0);
  }
}

@keyframes shadowBouncing {
  0%,
  100% {
    transform: scale(2, 0.8);
  }
  20% {
    transform: scale(1.2, 0.8);
  }
  40%,
  80% {
    transform: scale(0.5, 0.2);
  }
  70% {
    transform: scale(0.5, 0.15);
  }
  90% {
    transform: scale(1.5, 0.6);
  }
}
/* End ball bouncing */

/* Ball movement */
.ball-movement {
  background-color: var(--frangipani);
  overflow: hidden;
}

.ball-movement::before {
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 1rem;
  bottom: 2rem;
  content: "";
  height: 0.15rem;
  left: 2rem;
  position: absolute;
  right: 2rem;
}

.ball-movement .ball {
  animation: ballMovement 1.6s cubic-bezier(0, 0.98, 1, 0.99) infinite;
  bottom: 2.1rem;
  transform: translate(10rem, -10rem) scale(1.2, 0.5);
  transform-origin: bottom;
}

@keyframes ballMovement {
  0% {
    transform: translate(10rem, -15rem);
  }
  15% {
    transform: translate(7rem, 0rem) scale(1.2, 0.5);
  }
  30% {
    transform: translate(2rem, -10rem) scale(1, 1);
  }
  45% {
    transform: translate(-2rem, 0rem) scale(1.2, 0.8);
  }
  60% {
    transform: translate(-5rem, -5.5rem) scale(1, 1);
  }
  75% {
    transform: translate(-8rem, 0rem) scale(1.1, 0.9);
  }
  100% {
    transform: translate(-10rem, -3rem) scale(1, 1);
  }
}
/* End ball movement */

/* Counter */
.counter {
  animation: counter 5s linear infinite alternate;
  background-color: var(--sundown);
  counter-reset: count 0;
}

.counter::before {
  color: var(--white);
  content: counter(count);
  font-size: 7rem;
  line-height: 1.1;
  text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.3);
}

@keyframes counter {
  0% {
    counter-increment: count 0;
  }
  10% {
    counter-increment: count 1;
  }
  20% {
    counter-increment: count 2;
  }
  30% {
    counter-increment: count 3;
  }
  40% {
    counter-increment: count 4;
  }
  50% {
    counter-increment: count 5;
  }
  60% {
    counter-increment: count 6;
  }
  70% {
    counter-increment: count 7;
  }
  80% {
    counter-increment: count 8;
  }
  90% {
    counter-increment: count 9;
  }
  100% {
    counter-increment: count 10;
  }
}
/* End counter */
